import React, { useState } from 'react';
import { LUCIANO_PRIVATE, MEMBERSHIPS, PRIVATES } from '../data/siteConstants';
import TrialButton from '../components/TrialButton';
const PricingComponent = ({
  title,
  monthlySubTitle,
  monthlyPrice,
  annualPrice,
  annualLink,
  annualSubTitle,
  monthlyLink,
  showAnnualPrice,
  dayPass,
  comingSoon,
  joiningFee,
  saveAnnual,
  hourly = true,
}) => {
  const displayPrice = showAnnualPrice ? annualPrice : monthlyPrice;
  const monthlyOrAnnual = showAnnualPrice ? 'year' : 'mo';
  const link = showAnnualPrice ? annualLink : monthlyLink;
  const subTitle = showAnnualPrice ? annualSubTitle : monthlySubTitle;

  const handlePricingClick = () => {
    window.location.href = link;
  };

  const subPartFigure = showAnnualPrice ? saveAnnual : joiningFee;
  const subPartCopy = showAnnualPrice ? 'save ' : '+ joining fee ';
  return (
    <div
      role="listitem"
      onClick={handlePricingClick}
      className="relative z-30 p-8 my-6 rounded-lg shadow cursor-pointer bg-gray-50 hover:shadow-slate-200 hover:shadow-lg hover:bg-gray-100"
    >
      {comingSoon && (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full text-center bg-gray-200 rounded-lg opacity-80">
          <h1 className="text-3xl font-bi lg:text-5xl">COMING APRIL 2022</h1>
        </div>
      )}
      <div className="items-center justify-between md:flex">
        <h2 className="text-2xl font-semibold leading-6 text-gray-800">
          {title}
        </h2>
        <div className="flex flex-col md:items-end">
          <p className="mt-4 text-2xl font-semibold leading-6 text-gray-800 md:mt-0">
            {displayPrice}
            {hourly && (
              <>
                {dayPass ? (
                  <span className="text-base font-normal">/hour</span>
                ) : (
                  <span className="text-base font-normal">
                    /{monthlyOrAnnual}
                  </span>
                )}
              </>
            )}
          </p>
          {joiningFee && (
            <p className="mt-0 text-lg font-semibold leading-6 md:mt-0 text-gray-800/60">
              <span className="text-base font-normal">{subPartCopy}</span>
              {subPartFigure}
            </p>
          )}
        </div>
      </div>
      <p className="mt-4 text-base leading-6 text-gray-600 md:w-80">
        {subTitle}
      </p>
    </div>
  );
};

const PrivateComponent = ({ title, prices }) => {
  const [activePrice, setActivePrice] = useState(0);

  const subTitle = prices[activePrice].description;

  const displayPrice = prices[activePrice].price;

  const link = prices[activePrice].link;

  const handlePricingClick = () => {
    window.location.href = link;
  };

  const activeToggleClassName =
    'bg-slate-700 focus:outline-none text-base leading-none text-white font-b rounded py-4 px-7';

  const inActiveToggleClassName =
    'bg-gray-100 focus:outline-none text-base leading-none text-black font-r rounded py-4 px-6';

  const privateLabels = ['x1', 'x6', 'x12', 'x24', 'Welcome Pack'];

  return (
    <div
      role="listitem"
      onClick={handlePricingClick}
      className="relative z-30 p-8 my-6 rounded-lg shadow cursor-pointer bg-gray-50 hover:shadow-slate-200 hover:shadow-lg hover:bg-gray-100"
    >
      <div className="items-center justify-between md:flex">
        <h2 className="text-2xl font-semibold leading-6 text-gray-800">
          {title}
        </h2>
        <div className="flex flex-col md:items-end">
          <p className="mt-4 text-2xl font-semibold leading-6 text-gray-800 md:mt-0">
            {displayPrice}
          </p>
        </div>
      </div>
      <p className="mt-4 text-base leading-6 text-gray-600 md:w-80">
        {subTitle}
      </p>
      <div
        className="flex flex-col space-y-6 w-max"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center mt-10 bg-gray-100 rounded shadow sm:flex-row">
          {privateLabels.map((label, index) => {
            return (
              <button
                className={
                  activePrice === index
                    ? activeToggleClassName
                    : inActiveToggleClassName
                }
                key={index}
                onClick={() => setActivePrice(index)}
              >
                {privateLabels[index]}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default function Pricing() {
  const [activePrice, setActivePrice] = useState('MONTHLY');

  const handlePriceToggleClick = (price) => {
    setActivePrice(price);
  };

  const activeToggleClassName =
    'bg-slate-700 focus:outline-none text-base leading-none text-white font-b rounded-full py-4 px-7';

  const inActiveToggleClassName =
    'bg-gray-100 focus:outline-none text-base leading-none text-black font-r rounded-full py-4 px-6';

  return (
    <>
      <div className="px-6 py-20 mt-4 xl:mx-auto xl:container 2xl:px-0">
        <div className="items-center justify-between lg:flex">
          <div className="w-full lg:w-1/2">
            <p className="text-base leading-4 text-gray-600">
              Choose your plan
            </p>
            <h1 className="mt-3 text-3xl font-bold leading-10 text-gray-800 md:text-5xl">
              Our membership options
            </h1>
            <p
              role="contentinfo"
              className="hidden mt-5 text-base leading-5 text-gray-600 sm:flex"
            >
              Please select a membership option from below to get started.
            </p>
            <div className="flex flex-col space-y-6 w-max">
              <div className="flex items-center mt-10 bg-gray-100 rounded-full shadow">
                <button
                  className={
                    activePrice === 'MONTHLY'
                      ? activeToggleClassName
                      : inActiveToggleClassName
                  }
                  id="monthly"
                  onClick={() => handlePriceToggleClick('MONTHLY')}
                >
                  Monthly
                </button>
                <button
                  className={
                    activePrice === 'ANNUAL'
                      ? activeToggleClassName
                      : inActiveToggleClassName
                  }
                  id="annually"
                  onClick={() => handlePriceToggleClick('ANNUAL')}
                >
                  Annually
                </button>
                <button
                  className={
                    activePrice === 'PRIVATES'
                      ? activeToggleClassName
                      : inActiveToggleClassName
                  }
                  id="privates"
                  onClick={() => handlePriceToggleClick('PRIVATES')}
                >
                  Privates
                </button>
              </div>
              <TrialButton />
            </div>
          </div>
          <div
            className="relative w-full mt-12 xl:w-1/2 lg:w-7/12 lg:mt-0 md:px-8"
            role="list"
          >
            {activePrice === 'PRIVATES' && (
              <>
                <PricingComponent
                  key={12345}
                  title={LUCIANO_PRIVATE.title}
                  monthlyPrice={LUCIANO_PRIVATE.monthlyPrice}
                  annualPrice={LUCIANO_PRIVATE.annualPrice}
                  showAnnualPrice={activePrice === 'ANNUAL'}
                  dayPass={LUCIANO_PRIVATE.dayPass}
                  monthlyLink={LUCIANO_PRIVATE.monthlyLink}
                  annualLink={LUCIANO_PRIVATE.annualLink}
                  monthlySubTitle={LUCIANO_PRIVATE.monthlySubTitle}
                  annualSubTitle={LUCIANO_PRIVATE.annualSubTitle}
                  comingSoon={LUCIANO_PRIVATE.comingSoon}
                  joiningFee={LUCIANO_PRIVATE.joiningFee}
                  saveAnnual={LUCIANO_PRIVATE.saveAnnual}
                  hourly={LUCIANO_PRIVATE.hourly}
                />
                {PRIVATES.map((membership, index) => {
                  return (
                    <PrivateComponent
                      key={index}
                      title={membership.title}
                      prices={membership.prices}
                    />
                  );
                })}
              </>
            )}
            {activePrice !== 'PRIVATES' &&
              MEMBERSHIPS.map((membership, index) => {
                return (
                  <PricingComponent
                    key={index}
                    title={membership.title}
                    monthlyPrice={membership.monthlyPrice}
                    annualPrice={membership.annualPrice}
                    showAnnualPrice={activePrice === 'ANNUAL'}
                    dayPass={membership.dayPass}
                    monthlyLink={membership.monthlyLink}
                    annualLink={membership.annualLink}
                    monthlySubTitle={membership.monthlySubTitle}
                    annualSubTitle={membership.annualSubTitle}
                    comingSoon={membership.comingSoon}
                    joiningFee={membership.joiningFee}
                    saveAnnual={membership.saveAnnual}
                    hourly={membership.hourly}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
