export const firstText =
  'Luciano began BJJ training at the age of six in Brazil.';

export const secondText =
  'Under the tutelage of world famous Jiu-Jitsu master Renzo Gracie and Roger Gracie, he received his blackbelt in July 2011.';

export const firstQuoteText =
  'My philosophy is simple. That is; the practice of Jiu-Jitsu goes beyond the execution of strikes, throws, chokes, immobilisations, and submissions. A true understanding and appreciation of the Jiu-Jitsu philosophy prepares you for life and everything it throws your way.';

export const secondQuoteText =
  'It guides you to a healthier lifestyle and the most efficient use of mental, physical, and spiritual strength. When you embrace the Gracie philosophy it becomes a way to live, a way to be a better human being.';

export const firstVivianaText = 'Viviana started boxing 10 years ago.';

export const secondVivianaText =
  'Viviana was told that she was too old and too fat to ever achieve anything in boxing and so she went winning three national championships in two different countries in three different weight categories..';

export const firstQuoteVivianaText =
  "Boxing can literally save your life! Boxing will teach you to overcome adversities, use your pain and be free of it. Boxing will give you confidence in your abilities, stability in your emotions and strength in your heart. It doesn't matter the size of your arms, what matters is the size of your heart!.";

export const secondQuoteVivianaText =
  "Despite your age, your weight, your height, your injuries, you can achieve anything you set your mind and heart to; learn to work with your body not against it. Come and try our boxing classes, you'll work hard whilst having fun!";

export const lucianoCompTitle =
  "Luciano has competed in the world's most prestigious BJJ events, winning the...";

export const lucianoCompetitions = [
  'World Championship',
  'European Championship',
  'Pan-American Championship',
];

export const vivianaCompTitle =
  'Viviana is an accomplished boxing competitor, having won...';

export const vivianaCompetitions = [
  'Italian National Championship 2010',
  'British National Championship 2012',
  'British National Championship 2013',
  'Irish Celtic Box Cup 2015',
];

export const renanStoryOne =
  "Born and raised in a small town in Brazil, football was my primary sport until one day, while browsing the internet, I came across a page about a Brazilian Jiu-Jitsu academy in my hometown. As I looked at the pictures on the page, the instructor's and his students' confidence caught my eye. Their broad smiles and their sense of camaraderie persuaded me to visit their gym and take my first Jiu-Jitsu lesson. I immediately fell in love with the sport, and I've been a part of the community ever since.";

export const renanStoryTwo =
  "In addition to teaching me fight methods and body synchronisation, the sport has also helped me develop a game-changing attitude that I have applied to all aspects of my daily life, including relationships and my education. I'm enrolled in a web development course at the University of Bath in addition to my Jiu-jitsu lifestyle.";

export const viniStoryOne =
  "I started Jiu-jitsu in 2009 in Italy, Verona, and I moved to London in 2011 as a blue belt. I've been training for  9 and a half years. Thanks to my parents' strong influence, sports and a healthy lifestyle have always been essential parts of my life. I've always wanted to learn how to fight and learn martial arts.";

export const viniStoryTwo =
  'When I was 18, I was looking for kickboxing classes and came across a club with BJJ, which I fell in love with. Aside from martial arts, I enjoy being outside in nature, especially camping, hiking, and rock climbing. Jiu-jitsu is a fantastic tool that has helped me in so many aspects of my life; now that I am an instructor, I am eager to teach and change the lives of others.';

export const johnStoryOne =
  "A Londoner born and bred, I started martial arts at the age of 17. I practised various styles such as Aikido, Japanese Jiujitsu, Kung Fu, Full Contact Stick Fighting and also achieved my black belt in Kempo Karate. Seeking to better challenge myself, I first got to try Jiu-jitsu whilst living in Dublin in 2006 and was hooked immediately. I have been fortunate to train in Paraestra (Tokyo) & London Fight Factory (London). Jiu-jitsu has always played a big part in my life, helping create good mental health and strengthening physical awareness. To me, it's a form of meditation.";

export const johnStoryTwo =
  'Working as a Personal Trainer in the City, Jiu-jitsu has given me the tools to help push myself and create discipline in my life. The discipline, mobility, strength and mental robustness I have acquired from Jiu-jitsu are the traits I strive to instil in those I teach and the clients I train.';

export const zionStoryOne =
  'Born and raised in London, I got into fitness training at the age of 15 when I started swimming, weight training and boxing. I eventually started working in the fitness industry and became a Level 3 qualified fitness instructor. Around 18 years ago, a friend of mine introduced me to an MMA gym, London Shootfighters, where I fell in love with the sport of grappling.';

export const zionStoryTwo =
  "Grappling became a powerful and calming influence in my life, providing me with confidence and control, which I have applied in all aspects of my life. I have since shifted career, but grappling is still a big part of my life and something I'm passionate about. I currently teach NO-GI fundamental classes at Luciano Cristovam Academy, where I enjoy a great sense of community and imparting my knowledge.";

export const claudioStoryOne =
  "Claudio is a London-based Ashtanga, Yoga for sports and movement teacher. As a former Basketball player, Yoga initially became a way to support athletic training and help mitigate injuries. He intends to create a space for students to explore and find their relationship to the practice. He believes in a non-dogmatic approach that focuses on each student's needs and aims. He strives to make his classes safe, biomechanically relevant and functional. Claudio brings knowledge of anatomy into his teaching, with a background in biology and sports massage.";

export const claudioStoryTwo =
  'As an advanced freediver, Claudio is also very keen on breathing practices that allow him and his students to have a true impact on the equilibrium of the nervous system. Claudio has been a BTEC level 5 Remedial Sports Masseur since 2014 and a Cranio Sacral Therapist. He specializes in injury rehabilitation and conditioning.';

export const milesStoryOne =
  "I started martial arts at the age of 16, beginning with kickboxing. Martial arts were the first thing that 'clicked' in my life. I found that the more I trained, the more focus and discipline I had in my life outside of training. I started Jiujitsu at 17 and got my blue belt at 18, then went on a five-year hiatus, training and competing in mixed martial arts.";

export const milesStoryTwo =
  'I have recently rediscovered my love for Jiujitsu and training in the gi, especially integrating the techniques and philosophies one has learnt from mixed martial arts into Jiujitsu. Teaching people Jiujitsu is rewarding for me as it means I can share all of the great things I have gained from it.';

export const aitchStoryOne =
  "I am a passionate martial artist hailing from North Carolina, USA, with a background in kickboxing, boxing, and Muay Thai. My martial arts journey led me to Brazilian Jiu-Jitsu, where I discovered not only a physical discipline but a profound intellectual pursuit. As a woman in BJJ, I'm committed to empowering others, especially women, to embrace this incredible art form.";

export const aitchStoryTwo =
  'I deeply appreciate the philosophy of BJJ, where discipline, patience, and technique extend beyond the mat. As a trainer, I aim to share my passion for the sport and create a supportive space where everyone can train comfortably and be motivated to reach their full potential.';

export const pawelStoryOne =
  'I started practising martial arts (Kung-fu, then Boxing) at the age of twelve, inspired by Bruce Lee movies! At 25, I emigrated to Dublin to study jazz. I stopped training, to protect my hands. In 2009, I reconnected with martial arts. The documentary “Choke” about Rickson Gracie led me to join SBG Ireland and begin my Jiu Jitsu journey under the tutelage of John Kavanagh. Frequent moves and career changes made me a Jiu Jitsu nomad.';

export const pawelStoryTwo =
  'I trained at Ribeiro jiu jitsu (current ECJJA), Abel Martínez BJJ, Fightzone London, Gracie Barra Lagarto, Mill Hill BJJ and Studio NW2, among others. I joined LCA in the beginning of 2023. I believe in smart training supported by a healthy lifestyle that will keep you on the mat for the rest of your life - "It\'s not who is the best, but who is left” as Chris Hauter said. Off the mat, I work as an osteopath and enjoy nature walks and wild swimming!';

export const joaoStoryOne =
  "Hello, I'm Joao Ulysses, and I hail from the vibrant city of Rio de Janeiro, Brazil. I'm a passionate 25-year-old with a profound love for Brazilian Jiu-Jitsu. Fluent in both Portuguese and English, I've spent a significant part of my life honing my skills and pushing the boundaries of what the human body can achieve. My journey in physical discipline started at a young age when I delved into the world of Olympic gymnastics. I quickly became engrossed in the sport, dedicating myself to rigorous training and competition. The fruits of my labor were evident as I proudly clinched Gold in numerous prestigious competitions, representing the International Gymnastics Federation.";

export const joaoStoryTwo =
  "My gymnastics background equipped me with an incredible level of body control and awareness. This invaluable skill set seamlessly transitioned into the realm of Brazilian Jiu-Jitsu. With the grace and precision of a gymnast, I've learned to apply my unique talents to the art of grappling, and I'm eager to share this expertise with others. My journey in these two worlds has allowed me to harness an unparalleled understanding of the human body and its capabilities. As your Brazilian Jiu-Jitsu coach, I am dedicated to helping you unlock your full potential and elevate your skills to new heights. Join me on this exhilarating journey, where the art of Jiu-Jitsu meets the finesse of gymnastics, creating a truly dynamic and effective approach to mastering this ancient martial art.";

export const betoStoryOne =
  "Born and raised in Rio de Janeiro, the cradle of world jiu-jitsu, I had my first contact with the sport as a child through my uncle, who was friends with members of the Gracie family. Away from the mats as a teenager, at the age of 18 I returned to training with the Soul Fighters team, where I graduated as a black belt under master Bruno 'Tank' Mendes.";

export const betoStoryTwo =
  "I currently have a degree in Architecture and Urbanism from the Federal University of Rio de Janeiro, and I'm an artist with over 20 years' experience in the painting and illustration market. Art and jiu-jitsu are two passions that have always gone hand in hand in my life.";

export const faisalStoryOne =
  'Although I was raised in London, my journey in jiu-jitsu started In 2010 at the Gracie Academy in California. During this time, my passion for jiu-jitsu grew, and my curiosity for the history and origins of jiu-jitsu increased. Around 2011, I got connected with the Valente Brothers. I have been a student of theirs since, and I was very fortunate enough to have travelled to Miami many times throughout the years and attended their seminars in the UK and across Europe.';

export const faisalStoryTwo =
  'I really became intrigued with the teaching and style of jiu-jitsu that the Valente brothers focused on. They focus on the 5 elements of jiu-jitsu that were taught to them by Grand Master Helio Gracie and Grand Master Dr Pedro Valente Sr, which consist of Techniques of self-defence against surprise attacks, Striking techniques, grappling techniques, throwing techniques, and philology. I incorporate the philosophy of jiu-jitsu into my everyday life, which has grounded me. I also want to pass on the knowledge that I have gained and continue to gain in my learning of this great art.';

export const louisStoryOne =
  'Born and raised in North West London, rugby was my main sport growing up until I played my last game at University in 2015. I then spent the next five years focusing on self-development, physically and mentally, which led me to Brazilian Jiu-Jitsu. I started training at a local gym at the end of 2020 before going to Roger Gracie HQ in Hammersmith, where I received my blue belt in 2021 under Luciano Cristovam. Jiu Jitsu is so much more than a sport for me. It’s a philosophy that starts on the mats but will undoubtedly spill over into your personal life. I will be forever grateful for the humility, discipline, and confidence it has taught me. I encourage everyone I meet to find their local gym and give it a go… oftentimes without them even mentioning jiu-jitsu!';

export const louisStoryTwo =
  'The best thing about Jiu-Jitsu is that it is for everyone, and it’s for life. It’s humbling, challenging, and, above all else, really good fun. Teaching jiu-jitsu is incredibly rewarding for me and something I thoroughly enjoy. When I’m not on the mats, I have a deep passion for fitness and well-being and can usually be found in the gym, listening to podcasts, reading books, or, of course, watching BJJ videos.';

export const hasnainStoryOne =
  'I started my Brazilian Jiu-Jitsu journey two years ago, though my love for grappling goes back to growing up with brothers and friends. BJJ has been a game-changer for me, helping me become more confident, disciplined, and focused. It’s also been a crucial part of my weight loss journey, which has been an incredible bonus.';

export const hasnainStoryTwo =
  'As a coach, I strive to share the benefits of BJJ with others, focusing on building solid technique and mental toughness. I believe BJJ can transform not just the body, but the mind, and I’m passionate about helping my students grow on and off the mats.';

export const nellyStoryOne =
  "I was born in London and began my Brazilian Jiu-Jitsu journey at the age of 11 at Roger Gracie’s academy in Shepherd's Bush. I trained consistently for three years and achieved my junior grey belt. After a long break, I returned to the mats in 2024, joining LCBJJ. I began coaching and teaching physical education at the age of 16, which rekindled my passion for martial arts. I was fortunate to meet Luciano, who became a key mentor in my coaching development.";

export const nellyStoryTwo =
  "Training in Brazilian Jiu-Jitsu from such a young age instilled a strong sense of discipline in me, which continues to guide me today. I'm inspired by the legacy of Jiu-Jitsu and am deeply committed to growing as a coach while learning more about the art with every step I take.";

export const INSTRUCTORS = [
  {
    name: 'Beto',
    teaches: 'Black Belt',
    copyOne: betoStoryOne,
    copyTwo: betoStoryTwo,
    image: 'images/BETO.jpg',
    id: 'betoDiv',
  },
  {
    name: 'Renan',
    teaches: 'Brown Belt',
    copyOne: renanStoryOne,
    copyTwo: renanStoryTwo,
    image: 'images/RENAN.jpg',
    id: 'renanDiv',
  },

  {
    name: 'John',
    teaches: 'Black Belt',
    copyOne: johnStoryOne,
    copyTwo: johnStoryTwo,
    image: 'images/JOHN_NEW.jpg',
    id: 'johnDiv',
  },
  {
    name: 'Zion',
    teaches: 'Brown Belt',
    copyOne: zionStoryOne,
    copyTwo: zionStoryTwo,
    image: 'images/ZION_NEW.jpg',
    id: 'zionDiv',
  },
  {
    name: 'Miles',
    teaches: 'Purple Belt',
    copyOne: milesStoryOne,
    copyTwo: milesStoryTwo,
    image: 'images/MILES_NEW_NEW.jpg',
    id: 'milesDiv',
  },
  {
    name: 'Mohamed',
    teaches: 'Purple Belt',
    copyOne: faisalStoryOne,
    copyTwo: faisalStoryTwo,
    image: 'images/MO.jpg',
    id: 'mohamedDiv',
  },
  {
    name: 'Louis',
    teaches: 'Blue Belt',
    copyOne: louisStoryOne,
    copyTwo: louisStoryTwo,
    image: 'images/LOUIS.jpg',
    id: 'louisDiv',
  },
  {
    name: 'Hasnain',
    teaches: 'Blue Belt',
    copyOne: hasnainStoryOne,
    copyTwo: hasnainStoryTwo,
    image: 'images/HASNAIN.jpg',
    id: 'hasnainDiv',
  },
  {
    name: 'Nelly',
    teaches: 'White Belt',
    copyOne: nellyStoryOne,
    copyTwo: nellyStoryTwo,
    image: 'images/NELLY.jpeg',
    id: 'nellyDiv',
  },
  {
    name: 'Claudio',
    teaches: 'Yoga',
    copyOne: claudioStoryOne,
    copyTwo: claudioStoryTwo,
    image: 'images/claudio.jpg',
    id: 'claudioDiv',
  },
];
