export const ADDRESS = {
  TITLE: 'Luciano Cristovam BJJ',
  LINE_ZERO: 'Unit 5, Holmes Studios',
  LINE_ONE: '45 Holmes Road',
  LINE_TWO: 'London',
  POSTCODE: 'NW5 3AN',
  COUNTRY: 'UK',
};

export const HOURS = {
  MONDAY: '7am - 8pm',
  TUESDAY: '7am - 8pm',
  WEDNESDAY: '7am - 8pm',
  THURSDAY: '7am - 8pm',
  FRIDAY: '9am - 8pm',
  SATURDAY: '8am - 2pm',
  SUNDAY: '12:30pm - 2:30pm',
};

export const PROMO = {
  title: 'No Joining Fee',
  subTitle: 'For the first 3 months enjoy no joining fee. Usual price £120.',
  button: true,
  buttonText: 'JOIN NOW',
  buttonLink: 'https://www.lucianocristovam.com/pricing',
};

export const SOCIALS = {
  INSTAGRAM: 'https://www.instagram.com/lucianocristovamacademy/?hl=en',
  FACEBOOK: 'https://www.facebook.com/Luciano-Cristovam-BJJ-243076049362025/',
};

export const SITE_TITLE = {
  title: 'Luciano Cristovam',
  subTitle: 'Brazilian Jiu Jitsu',
};

export const DETAILS = {
  PHONE: '+44 7498 471 113',
  EMAIL: 'info@lucianocristovam.com',
};

export const FREE_TRIAL_LINK =
  'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622390744bea6d0ed4429c25/plan/1646497878574/buy';

export const MEMBERSHIPS = [
  {
    title: 'Adults',
    monthlySubTitle:
      'Joining fee includes LC official kimono. Unlimited membership for adults from 16 years old.',
    monthlyPrice: '£160',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622390eba82ac57ba7750377/plan/1646498011325/buy',
    annualPrice: '£1600',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622391918b6756374d6f119f/plan/1646498171089/buy',
    annualSubTitle:
      'Includes LC official kimono. Unlimited membership for adults from 16 years old.',
    dayPass: false,
    joiningFee: '£100',
    saveAnnual: '£320',
  },
  {
    title: 'Kids',
    monthlySubTitle:
      'Joining fee includes LC official kimono. Unlimited membership for kids up to 16 years old.',
    monthlyPrice: '£120',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/62239154072dbd42ce5b04d8/plan/1646498123510/buy',
    annualPrice: '£1200',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622391ab4efe556a73040590/plan/1646498208628/buy',
    annualSubTitle:
      'Includes LC official kimono. Unlimited membership for kids up to 16 years old.',
    dayPass: false,
    comingSoon: false,
    joiningFee: '£95',
    saveAnnual: '£240',
  },

  {
    title: 'Yoga Class',
    monthlySubTitle: 'One off pass for Yoga Class',
    monthlyPrice: '£13',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6438856191f7a7171900a66a/plan/1681425672012/buy',
    annualPrice: '£13',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6438856191f7a7171900a66a/plan/1681425672012/buy',
    annualSubTitle: 'One off pass for Yoga Class',
    dayPass: true,
  },
];

export const LUCIANO_PRIVATE = {
  title: 'Private with Prof. Luciano',
  monthlySubTitle: '1 hour private class with Professor Luciano Cristovam',
  monthlyPrice: '£200',
  monthlyLink:
    'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6552b2dffc7d683d2c0d014e/plan/1699918519372/buy',
  annualPrice: '£200',
  annualLink:
    'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6552b2dffc7d683d2c0d014e/plan/1699918519372/buy',
  annualSubTitle: '1 hour private class with Professor Luciano Cristovam',
  dayPass: true,
};

export const PRIVATES = [
  {
    title: 'Private with Coach Miles',
    prices: [
      {
        price: '£77',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6571ebd7dc3cb43a3f0cd61f/plan/1701964699815/buy',
        description: '1 hour private class with Coach Miles Emrys',
      },
      {
        price: '£386',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6571ebd7dc3cb43a3f0cd61f/plan/1729618329189/buy',
        description:
          '6-Pack Skill Development Package with Coach Miles. Get 1 free class.',
      },
      {
        price: '£770',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/64d67fe1a2c24123de0d2508/plan/1729617012149/buy',
        description:
          '12-Pack Skill Development Package with Coach Miles. Get 2 free classes.',
      },
      {
        price: '£1,540',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6571ebd7dc3cb43a3f0cd61f/plan/1729618454892/buy',
        description:
          '24-Pack Skill Development Package with Coach Miles. Get 4 free classes.',
      },
      {
        price: '£210',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6727e2ae5d922c99e207d323/plan/1730667134481/buy',
        description: 'Welcome Package with Coach Miles. Save £20',
      },
    ],
  },

  {
    title: 'Private with Coach Renan',
    prices: [
      {
        price: '£88',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/64d67fe1a2c24123de0d2508/plan/1691778804315/buy',
        description: '1 hour private class with Coach Renan',
      },
      {
        price: '£440',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/64d67fe1a2c24123de0d2508/plan/1729616790368/buy',
        description:
          '6-Pack Skill Development Package with Coach Renan. Get 1 free class.',
      },
      {
        price: '£880',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/64d67fe1a2c24123de0d2508/plan/1729617012149/buy',
        description:
          '12-Pack Skill Development Package with Coach Renan. Get 2 free classes.',
      },
      {
        price: '£1,760',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/64d67fe1a2c24123de0d2508/plan/1729617513354/buy',
        description:
          '24-Pack Skill Development Package with Coach Renan. Get 4 free classes.',
      },
      {
        price: '£244',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6727e2ae5d922c99e207d323/plan/1730667134481/buy',
        description: 'Welcome Package with Coach Renan. Save £20',
      },
    ],
  },
  {
    title: 'Private with Professor Beto',
    prices: [
      {
        price: '£110',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/662e645c68b2c7cf8505e564/plan/1714316338244/buy',
        description: '1 hour private class with Professor Beto',
      },
      {
        price: '£550',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/662e645c68b2c7cf8505e564/plan/1729618718487/buy',
        description:
          '6-Pack Skill Development Package with Professor Beto. Get 1 free class.',
      },
      {
        price: '£1,100',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/662e645c68b2c7cf8505e564/plan/1729618811495/buy',
        description:
          '12-Pack Skill Development Package with Professor Beto. Get 2 free classes.',
      },
      {
        price: '£2,200',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/662e645c68b2c7cf8505e564/plan/1729618858704/buy',
        description:
          '24-Pack Skill Development Package with Professor Beto. Get 4 free classes.',
      },
      {
        price: '£280',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6727e2ae5d922c99e207d323/plan/1730667134481/buy',
        description: 'Welcome Package with Professor Beto. Save £20',
      },
    ],
  },
  {
    title: 'Private with Professor John Kiely',
    prices: [
      {
        price: '£110',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/673dcf886ce8bde4860b7bd6/plan/1732104033853/buy',
        description: '1 hour private class with Professor John Kiely',
      },
      {
        price: '£550',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/673dcf886ce8bde4860b7bd6/plan/1732104138992/buy',
        description:
          '6-Pack Skill Development Package with Professor John Kiely. Get 1 free class.',
      },
      {
        price: '£1,100',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/673dcf886ce8bde4860b7bd6/plan/1732104219960/buy',
        description:
          '12-Pack Skill Development Package with Professor John Kiely. Get 2 free classes.',
      },
      {
        price: '£2,200',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/673dcf886ce8bde4860b7bd6/plan/1732104259125/buy',
        description:
          '24-Pack Skill Development Package with Professor John Kiely. Get 4 free classes.',
      },
      {
        price: '£280',
        link: 'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6727e2ae5d922c99e207d323/plan/1730667134481/buy',
        description: 'Welcome Package with Professor John Kiely. Save £20',
      },
    ],
  },
];
